export const PH_FEATURE_FLAG = {
  interestButtonLabel: "register-interest-section",
} as const;

export const PH_EVENTS = {
  subscription: {
    openModal: "top_interest",
    dialogSubscribed: "top_subscribed",
    normalSubscribed: "bottom_subscribed",
    heroSection: "hero_interest",
  },
  validator: {
    heroSection: "hero_all_validators",
  },
  buyNow: 'top_buy_interest',
  purchase: {
    dev: 'pricing_dev_buy_interest',
    org: 'pricing_org_buy_interest'
  },
  demo: 'home_hero_demo'
};
